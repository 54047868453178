import axios from 'axios';
console.log("Base URL configurada:", process.env.REACT_APP_API_URL);

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Substitua pela URL base correta do seu backend
});

// Intercepta requisições para adicionar o token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
