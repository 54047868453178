import React, { useState } from 'react';

export const UpdatesPage = () => {
  const updates = [
    {
      id: 3,
      title: "Explicação em Etapas",
      description: "Adicionamos um guia em etapas para facilitar o uso das novas funcionalidades. Agora você pode seguir instruções diretamente no sistema com clareza e praticidade.",
      date: "2025-01-19",
      image: "/images/Steps.png",
    },
    {
      id: 4,
      title: "Ajuda e Suporte",
      description: "Incluímos um botão de ajuda para iniciar um tour guiado sempre que precisar. Isso ajuda a entender as funções diretamente na interface.",
      date: "2025-01-19",
      image: "/images/Help.png",
    },
    {
      id: 1,
      title: "Recorrência de Transações",
      description: "Agora você pode configurar transações recorrentes com frequências mensais, semanais ou diárias. Isso permite um controle mais eficiente sobre seus gastos recorrentes, como aluguel e assinaturas.",
      date: "2025-01-18",
      image: "/images/Recorrencia.png",
    },
    {
      id: 2,
      title: "Paginação de Transações",
      description: "Adicionamos paginação à tabela de transações para melhorar a organização e facilitar a navegação entre suas despesas e receitas.",
      date: "2025-01-17",
      image: "/images/Paginacao.png",
    }
  ];

  const [modalImage, setModalImage] = useState(null);

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Novas Atualizações</h1>
      {updates.length > 0 ? (
        <ul className="space-y-8">
          {updates.map((update) => (
            <li
              key={update.id}
              className="border border-gray-300 rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow"
            >
              <div className="p-6">
                <h2 className="text-xl font-semibold text-blue-600">{update.title}</h2>
                <p className="text-gray-700 mt-2">{update.description}</p>
                <span className="text-sm text-gray-500 mt-4 block">
                  {new Date(update.date).toLocaleDateString("pt-BR")}
                </span>
              </div>
              <div className="relative overflow-hidden">
                <img
                  src={update.image}
                  alt={update.title}
                  className={`rounded-b-lg ${
                    update.id === 4
                      ? "object-contain max-h-40" // Ajuste para imagens pequenas
                      : update.id === 2
                      ? "object-bottom w-full h-60" // Posicionamento para imagens grandes (inferior)
                      : "object-cover w-full h-60" // Padrão para outras imagens
                  }`}
                  onClick={() => openModal(update.image)}
                  style={{ cursor: "pointer" }} // Cursor para indicar interatividade
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-center text-gray-600">Não há novas atualizações no momento.</p>
      )}

      {/* Modal para expansão da imagem */}
      {modalImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative">
            <img
              src={modalImage}
              alt="Expanded"
              className="max-w-full max-h-full rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 bg-white text-gray-700 rounded-full w-8 h-8 flex items-center justify-center shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};