import { createContext, useState, useContext, useEffect } from "react";
import api from "../services/api";
import { initializeSocket, getSocket } from "../services/webSocket";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // Armazena os dados completos do usuário
  const [socketInitialized, setSocketInitialized] = useState(false); // Garante que o WebSocket seja inicializado apenas uma vez

  const fetchPendingNotifications = async (userId) => {
    try {
      const response = await api.get("/notifications/pending");
      const pendingNotifications = response.data;

      console.log("🔔 Notificações pendentes recebidas:", pendingNotifications);

      // Exibir as notificações no frontend
      pendingNotifications.forEach((notification) => {
        const socket = getSocket();
        if (socket) {
          socket.emit("notification", notification);
        }
      });
    } catch (error) {
      console.error("Erro ao buscar notificações pendentes:", error);
    }
  };


  const signIn = async (email, password) => {
    try {
      const response = await api.post("/users/login", { email, password });
      const { token, user } = response.data;

      setIsAuthenticated(true);
      setUser(user);
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));

      // Inicializa o WebSocket com o userId
      if (user?.id && !socketInitialized) {
        console.log("🔌 Inicializando WebSocket após login para usuário:", user.id);
        initializeSocket(user);
        setSocketInitialized(true); // Marca o WebSocket como inicializado
      }
    } catch (error) {
      console.error("Login failed:", error.response?.data || error.message);
      throw new Error(error.response?.data?.error || "Login failed");
    }
  };

  const signOut = () => {
    setIsAuthenticated(false);
    setUser(null);
    setSocketInitialized(false); // Reseta o estado do WebSocket
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    // Desconecta o WebSocket ao sair
    const socket = getSocket();
    if (socket) {
      console.log("🔌 Desconectando WebSocket...");
      socket.disconnect();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");

    try {
      if (token && storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setIsAuthenticated(true);
        setUser(parsedUser);

        // Inicializa o WebSocket apenas se ainda não estiver inicializado
        const socket = getSocket();
        if (!socket || socket.disconnected) {
          console.log("🔌 Reconectando WebSocket para usuário armazenado:", parsedUser.id);
          initializeSocket(parsedUser);
          setSocketInitialized(true); // Marca o WebSocket como inicializado

          // Buscar notificações pendentes
          console.log("PARSED USER ID: ",parsedUser.id);
          fetchPendingNotifications(parsedUser.id);
        }

      }
    } catch (error) {
      console.error("Erro ao carregar usuário do localStorage:", error);
      signOut(); // Limpa dados inválidos
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, setUser, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
