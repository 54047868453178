import React from "react";
import { Link } from "react-router-dom";

export const PaymentPending = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500">
      <div className="max-w-lg p-6 bg-white rounded-lg shadow-lg text-center">
        <h1 className="text-3xl font-bold text-yellow-600 mb-4">Pagamento Pendente</h1>
        <p className="text-gray-700 mb-6">
          Estamos aguardando a confirmação do seu pagamento. Assim que for aprovado, você poderá acessar os benefícios.
        </p>
        <Link
          to="/"
          className="py-2 px-4 bg-yellow-500 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300"
        >
          Voltar para o Início
        </Link>
      </div>
    </div>
  );
};