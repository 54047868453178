import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showSuccessToast, showErrorToast } from "../utils/toast";

export const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paymentId = params.get("payment_id");
    const email = params.get("external_reference"); // Passe o e-mail no `external_reference`

    const verifyPayment = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/status`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ paymentId, email }),
        });

        const data = await response.json();
        if (data.status === "approved") {
          showSuccessToast("Pagamento aprovado! Registrando sua conta...");
          navigate("/signin");
        } else {
          showErrorToast("Pagamento não aprovado.");
          navigate("/payment-failure");
        }
      } catch (error) {
        console.error("Erro ao verificar status do pagamento:", error);
        showErrorToast("Erro ao processar o pagamento.");
      }
    };

    verifyPayment();
  }, [location.search, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-teal-500 via-indigo-500 to-blue-500">
      <h1 className="text-2xl font-bold text-white">Processando pagamento...</h1>
    </div>
  );
};
