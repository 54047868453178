import React, { useState, useEffect, useRef } from 'react';
import { showSuccessToast, showErrorToast } from "../utils/toast";
import api from '../services/api';
import { useAuth } from "../contexts/AuthContext";
import { formatDate, formatCurrency } from "../utils/index";
import { Dropdown } from "../components/Dropdown"; // Supondo que o componente Dropdown esteja nessa pasta
import { Tour } from "../utils/Tour";


export const GoalsPage = () => {
  const [goals, setGoals] = useState([]);
  const { user } = useAuth(); // Recupera o plano do usuário
  const [categories, setCategories] = useState([]);
  const [newGoal, setNewGoal] = useState({
    name: '',
    targetAmount: '',
    deadline: '',
    categoryId: '',
  });
  const [editingGoalId, setEditingGoalId] = useState(null); // ID da meta que está sendo editada
  const [loading, setLoading] = useState(true);
  const [runTour, setRunTour] = useState(false);
  const dropdownRef = useRef(null); // Ref para o Dropdown

  const steps = [
    {
      target: "#goal-form",
      content: "Aqui você pode criar ou editar uma meta. Preencha os campos e clique em 'Salvar Meta'.",
    },
    {
      target: "#goal-name-input",
      content: "Este campo é para o nome da meta. Exemplo: 'Casa', 'Carro', ou 'Viagem'.",
    },
    {
      target: "#goal-target-amount-input",
      content: "Este campo é o valor alvo. Insira o total que você deseja acumular ou guardar.",
    },
    {
      target: "#goal-deadline-input",
      content: "Aqui você define o prazo para atingir sua meta. Escolha uma data de término.",
    },
    {
      target: "#goal-category-dropdown",
      content: "Selecione uma categoria para vincular à meta ou escolha criar uma automaticamente.",
    },
    {
      target: "#goal-list",
      content: "Esta seção exibe suas metas cadastradas. Aqui você pode acompanhar o progresso e os detalhes de cada meta.",
    },
    {
      target: ".progress-bar",
      content: "Esta barra mostra o progresso da meta em relação ao valor alvo. O preenchimento aumenta conforme você se aproxima do objetivo.",
    },
    {
      target: ".edit-goal-button",
      content: "Clique aqui para editar os detalhes de uma meta existente.",
    },
    {
      target: ".delete-goal-button",
      content: "Clique aqui para excluir uma meta. Essa ação não pode ser desfeita.",
    }
  ];

  const handleTourCallback = (data) => {
    const { status, index } = data;

    // Abrir o dropdown no passo específico
    if (index === 4 && dropdownRef.current) {
      dropdownRef.current.click(); // Simula o clique no dropdown
    }

    // Finalizar o tour
    if (status === 'finished' || status === 'skipped') {
      setRunTour(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Carrega metas
        const goalsResponse = await api.get('/goals');
        setGoals(goalsResponse.data);

        // Carrega categorias para vincular às metas
        const endpoint =
          user?.plan?.name === "Basic" ? "/categories/basic" : "/categories/premium"; // Diferencia endpoint por plano
        const categoriesResponse = await api.get(endpoint);
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user?.plan?.name]);

  const handleSaveGoal = async () => {
    try {
      if (!newGoal.name || !newGoal.targetAmount || isNaN(newGoal.targetAmount)) {
        alert('Preencha todos os campos obrigatórios e insira um valor válido.');
        return;
      }
  
      let categoryId = newGoal.categoryId;
  
      // Criar uma nova categoria automaticamente, se necessário
      if (categoryId === 'auto') { // Ajuste aqui para verificar 'auto'
        const categoryResponse = await api.post('/categories', {
          name: `Meta: ${newGoal.name}`,
          color: '#4CAF50',
        });
        showSuccessToast(`Categoria automática: Meta: ${newGoal.name}\ncriada com sucesso!`);
        categoryId = categoryResponse.data.id;
      }
  
      const goalData = { ...newGoal, categoryId };
  
      // Salvar ou atualizar a meta
      if (editingGoalId) {
        await api.put(`/goals/${editingGoalId}`, goalData);
        showSuccessToast(`Meta atualizada com sucesso!`);
      } else {
        await api.post('/goals', goalData);
        showSuccessToast(`Meta criada com sucesso!`);
      }
  
      const response = await api.get('/goals');
      setGoals(response.data);
  
      setNewGoal({ name: '', targetAmount: '', deadline: '', categoryId: '' });
      setEditingGoalId(null);
    } catch (error) {
      console.error('Erro ao salvar meta:', error);
      showErrorToast("Erro ao salvar meta. Tente novamente!\n", error.response?.data?.error);
    }
  };  

  const handleDeleteGoal = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir esta meta?')) {
      try {
        await api.delete(`/goals/${id}`);

        // Atualizar a lista de metas após a exclusão
        const response = await api.get('/goals');
        setGoals(response.data);
      } catch (error) {
        console.error('Erro ao excluir meta:', error);
        alert('Erro ao excluir meta. Tente novamente.');
      }
    }
  };

  const handleEditGoal = (goal) => {
    setNewGoal({
      name: goal.name,
      targetAmount: goal.targetAmount,
      deadline: formatDate(goal.deadline),
      categoryId: goal.categoryId,
    });
    setEditingGoalId(goal.id); // Define a meta que está sendo editada
  };

  const handleCancelEdit = () => {
    setNewGoal({ name: '', targetAmount: '', deadline: '', categoryId: '' });
    setEditingGoalId(null); // Sai do modo de edição
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <Tour steps={steps} runTour={runTour} setRunTour={setRunTour} callback={handleTourCallback} />
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-teal-600 text-center mb-6">
          Planejamento de Metas
        </h1>

        {/* Formulário de Nova Meta */}
        <div id="goal-form" className="bg-white p-6 rounded-lg shadow-md mb-8">
          <h2 className="text-xl font-bold mb-4">{editingGoalId ? 'Editar Meta' : 'Nova Meta'}</h2>
          <input
            id="goal-name-input"
            type="text"
            className="border rounded-lg p-2 w-full mb-4"
            placeholder="Nome da meta"
            value={newGoal.name}
            onChange={(e) => setNewGoal({ ...newGoal, name: e.target.value })}
          />
          <input
            id="goal-target-amount-input"
            type="number"
            className="border rounded-lg p-2 w-full mb-4"
            placeholder="Valor alvo"
            value={newGoal.targetAmount}
            onChange={(e) => setNewGoal({ ...newGoal, targetAmount: e.target.value })}
          />
          <input
            id="goal-deadline-input"
            type="date"
            className="border rounded-lg p-2 w-full mb-4"
            value={newGoal.deadline}
            onChange={(e) => setNewGoal({ ...newGoal, deadline: e.target.value })}
          />
          <div id="goal-category-dropdown" ref={dropdownRef}>
            <Dropdown
              id="goal-category-dropdown"
              options={[
                { value: 'auto', label: 'Criar nova categoria automaticamente' }, // Ajuste aqui o value
                ...categories.map((cat) => ({ value: cat.id, label: cat.name })),
              ]}
              value={newGoal.categoryId}
              onChange={(categoryId) => {
                // Atualiza o valor e exibe como selecionado
                setNewGoal({ ...newGoal, categoryId });
              }}
              placeholder="Selecione uma Categoria"
            />
          </div>

          <div className="flex justify-between mt-4">
            <button
              className="bg-teal-600 text-white px-4 py-2 rounded-lg hover:bg-teal-700 transition-all"
              onClick={handleSaveGoal}
            >
              {editingGoalId ? 'Atualizar Meta' : 'Salvar Meta'}
            </button>
            {editingGoalId && (
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-gray-500 transition-all"
                onClick={handleCancelEdit}
              >
                Cancelar
              </button>
            )}
          </div>
        </div>

        {/* Lista de Metas */}
        <div id="goal-list">
          {loading ? (
            <p className="text-center text-gray-500">Carregando...</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {goals.map((goal) => {
                const progressPercentage = Math.min(
                  (goal.progress / goal.targetAmount) * 100,
                  100
                );

                return (
                  <div key={goal.id} className="bg-white p-6 rounded-lg shadow-md">
                    <h3 className="text-lg font-bold text-teal-600">{goal.name}</h3>
                    <p className="text-gray-700">
                      Categoria: {categories.find((cat) => cat.id === goal.categoryId)?.name || 'Sem Categoria'}
                    </p>
                    <p className="text-gray-700">
                      Alvo: {formatCurrency(goal.targetAmount)}
                    </p>
                    <p className="text-gray-500">
                      Prazo: {new Date(goal.deadline).toLocaleDateString()}
                    </p>
                    <div className="mt-4 progress-bar">
                      <div className="relative w-full h-4 bg-gray-200 rounded">
                        <div
                          className="absolute top-0 left-0 h-4 bg-teal-600 rounded"
                          style={{ width: `${progressPercentage}%` }}
                        ></div>
                      </div>
                      <p className="text-sm text-gray-600 mt-2">
                        Progresso: {formatCurrency(goal.progress || 0)} / {formatCurrency(goal.targetAmount)}
                      </p>
                    </div>
                    <div className="flex justify-between mt-4">
                      <button
                        onClick={() => handleEditGoal(goal)}
                        className="edit-goal-button text-blue-600 hover:underline"
                      >
                        Editar
                      </button>
                      <button
                        onClick={() => handleDeleteGoal(goal.id)}
                        className="delete-goal-button text-red-600 hover:underline"
                      >
                        Excluir
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
