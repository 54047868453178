import React from "react";

export const SupportPage = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header Section */}
      <header className="bg-gradient-to-r from-teal-600 via-green-500 to-teal-700 text-white py-20 px-6">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 animate-fade-in">
            Suporte e Ajuda
          </h1>
          <p className="text-lg md:text-xl mb-8 animate-fade-in text-gray-200">
            Estamos aqui para ajudar! Entre em contato conosco e saiba mais sobre
            essa funcionalidade.
          </p>
          <a
            href="#details"
            className="inline-block px-8 py-3 bg-white text-teal-600 font-bold rounded-lg shadow-md hover:bg-gray-100 transition-all duration-300"
          >
            Saiba Mais
          </a>
        </div>
      </header>

      {/* Details Section */}
      <section id="details" className="py-20 px-6">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-teal-600 mb-12">
            Funcionalidade Disponível
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            A funcionalidade já está disponível para todos os usuários. Caso
            tenha dúvidas ou precise de mais informações, nossa equipe de suporte
            está pronta para ajudar.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            Clique no botão abaixo para entrar em contato diretamente com nosso
            suporte via WhatsApp.
          </p>
          <a
            href="https://wa.me/55031995734976"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-8 py-3 bg-teal-600 text-white font-bold rounded-lg shadow-md hover:bg-teal-700 transition-all duration-300"
          >
            Fale com o Suporte
          </a>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="bg-teal-600 text-white py-20 px-6">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">
            Precisa de Ajuda Imediata?
          </h2>
          <p className="text-lg mb-8">
            Nossa equipe está disponível para garantir que você tenha a melhor
            experiência com o FinControl.
          </p>
          <a
            href="https://wa.me/55031995734976"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-8 py-3 bg-white text-teal-600 font-bold rounded-lg shadow-md hover:bg-gray-100 transition-all duration-300"
          >
            Contate-nos Agora
          </a>
        </div>
      </section>
    </div>
  );
};
