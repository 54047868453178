import React from "react";
import Joyride, { STATUS } from "react-joyride";

export const Tour = ({ steps, runTour, setRunTour }) => {
  const handleTourCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false); // Finaliza o tour
    }
  };

  return (
    <>
      {/* Componente Joyride */}
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        callback={handleTourCallback}
        locale={{
          back: "Voltar", // Texto do botão "Voltar"
          close: "Fechar", // Texto do botão "Fechar"
          last: "Finalizar", // Texto do botão "Finalizar"
          next: "Próximo", // Texto do botão "Próximo"
          skip: "Pular", // Texto do botão "Pular"
        }}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#1d4ed8", // Azul escuro como cor principal
            textColor: "#1f2937", // Cinza escuro para texto
            backgroundColor: "#f9fafb", // Fundo leve e claro
            overlayColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro translúcido para o overlay
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.8)", // Destaque no elemento ativo
          },
          buttonNext: {
            backgroundColor: "#1d4ed8", // Botão azul para "Próximo"
            color: "#ffffff", // Texto branco no botão
            fontSize: "14px", // Tamanho do texto no botão
            borderRadius: "8px", // Bordas arredondadas
          },
          buttonBack: {
            color: "#1d4ed8", // Texto azul para "Voltar"
            fontSize: "14px",
          },
          buttonSkip: {
            color: "#ef4444", // Texto vermelho para "Pular"
            fontSize: "14px",
          },
          buttonClose: {
            color: "#1f2937", // Cinza escuro para botão "Fechar"
            fontSize: "14px",
          },
          tooltip: {
            borderRadius: "8px", // Bordas arredondadas no balão
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)", // Sombra no balão
            fontSize: "14px", // Tamanho do texto
            padding: "20px", // Espaçamento interno
          },
          tooltipTitle: {
            fontWeight: "bold", // Negrito no título
            fontSize: "16px", // Tamanho maior no título
          },
          tooltipContent: {
            color: "#1f2937", // Cinza escuro para o conteúdo
          },
        }}
      />

      {/* Botão para iniciar o tour */}
      <button
        onClick={() => setRunTour(true)}
        className="fixed bottom-4 right-4 bg-teal-600 text-white rounded-full p-4 shadow-lg hover:bg-teal-700 transition-all duration-300 flex items-center justify-center"
        title="Iniciar Tour"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 8.25v3.75m0 3.75h.007M20.25 12c0 4.556-3.694 8.25-8.25 8.25S3.75 16.556 3.75 12 7.444 3.75 12 3.75 20.25 7.444 20.25 12z"
          />
        </svg>
      </button>
    </>
  );
};
