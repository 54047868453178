import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import api from "../services/api";
import { useNavigate } from "react-router-dom"; // Importe o useNavigate
import { showErrorToast, showSuccessToast, showWarningToast } from "../utils/toast";

export const EditAccountPage = () => {
  const { user, setUser } = useAuth(); // Certifique-se de que setUser está disponível
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [salary, setSalary] = useState(user?.salary || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate(); // Inicializa o hook useNavigate


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password && password !== confirmPassword) {
      showWarningToast("As senhas não coincidem.");
      return;
    }
  
    try {
      const payload = { name, email, salary };
      if (password) payload.password = password; // Inclui a senha apenas se for alterada
  
      const response = await api.put("/users/edit", payload);
  
      // Atualiza o usuário no contexto
      if (setUser) {
        setUser(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
      } else {
        console.warn("setUser não está disponível no contexto.");
      }
  
      showSuccessToast("Conta atualizada com sucesso!");
      navigate("/reports"); // Redireciona para a página Reports
    } catch (error) {
      console.error("Erro ao atualizar conta:", error);
      showErrorToast(
        error.response?.data?.error || "Erro ao atualizar conta. Tente novamente."
      );
    }
  };
  

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold mb-6">Editar Conta</h2>
      <form onSubmit={handleSubmit}>
        {/* Nome */}
        <div className="mb-4">
          <label className="block text-gray-700">Nome</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block text-gray-700">E-mail</label>
          <input
            type="email"
            className="w-full p-2 border rounded"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        {/* Salário */}
        <div className="mb-4">
          <label className="block text-gray-700">Salário</label>
          <input
            type="number"
            className="w-full p-2 border rounded"
            value={salary}
            onChange={(e) => setSalary(e.target.value || 0)}
          />
        </div>

        {/* Alterar Senha */}
        <div className="mb-4">
          <label className="block text-gray-700">Nova Senha</label>
          <input
            type="password"
            className="w-full p-2 border rounded"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Confirmar Nova Senha</label>
          <input
            type="password"
            className="w-full p-2 border rounded"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-teal-600 text-white rounded"
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};