import { NavLink } from "react-router-dom";
import { useState } from "react";

const VisitorNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="relative px-4 py-4 flex justify-between items-center bg-gray-100 shadow-md">
      {/* Logo */}
      <NavLink
        to="/"
        className="text-2xl font-bold leading-none text-teal-600"
      >
        FinControl
      </NavLink>

      {/* Mobile Menu Button */}
      <button
        className="lg:hidden flex items-center px-3 py-2 border rounded text-teal-600 border-teal-600 hover:text-teal-800 hover:border-teal-800"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <svg
          className="fill-current h-4 w-4"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>

      {/* Desktop Menu */}
      <ul className="hidden lg:flex lg:space-x-6">
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "text-sm text-teal-600 font-bold transition duration-300"
                : "text-sm text-gray-600 hover:text-teal-600 transition duration-300"
            }
          >
            Inicio
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive
                ? "text-sm text-teal-600 font-bold transition duration-300"
                : "text-sm text-gray-600 hover:text-teal-600 transition duration-300"
            }
          >
            Sobre Nós
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive
                ? "text-sm text-teal-600 font-bold transition duration-300"
                : "text-sm text-gray-600 hover:text-teal-600 transition duration-300"
            }
          >
            Serviços
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/pricing"
            className={({ isActive }) =>
              isActive
                ? "text-sm text-teal-600 font-bold transition duration-300"
                : "text-sm text-gray-600 hover:text-teal-600 transition duration-300"
            }
          >
            Planos
          </NavLink>
        </li>
      </ul>

      {/* Authentication Buttons */}
      <div className="hidden lg:flex">
        <NavLink
          to="/signin"
          className={({ isActive }) =>
            isActive
              ? "py-2 px-4 bg-gray-300 rounded-lg font-bold mr-3"
              : "py-2 px-4 bg-gray-200 hover:bg-gray-300 rounded-lg mr-3"
          }
        >
          Conectar
        </NavLink>
        <NavLink
          to="#"
          className="py-2 px-4 bg-gray-400 text-gray-700 rounded-lg font-bold cursor-not-allowed"
          onClick={(e) => e.preventDefault()}
        >
          Registrar
        </NavLink>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <ul className="absolute top-16 left-0 w-full bg-gray-100 shadow-lg lg:hidden">
          <li className="border-b border-gray-200">
            <NavLink
              to="/"
              className="block px-4 py-2 text-sm text-gray-600 hover:text-teal-600"
              onClick={() => setIsMenuOpen(false)}
            >
              Inicio
            </NavLink>
          </li>
          <li className="border-b border-gray-200">
            <NavLink
              to="/about"
              className="block px-4 py-2 text-sm text-gray-600 hover:text-teal-600"
              onClick={() => setIsMenuOpen(false)}
            >
              Sobre Nós
            </NavLink>
          </li>
          <li className="border-b border-gray-200">
            <NavLink
              to="/services"
              className="block px-4 py-2 text-sm text-gray-600 hover:text-teal-600"
              onClick={() => setIsMenuOpen(false)}
            >
              Serviços
            </NavLink>
          </li>
          <li className="border-b border-gray-200">
            <NavLink
              to="/pricing"
              className="block px-4 py-2 text-sm text-gray-600 hover:text-teal-600"
              onClick={() => setIsMenuOpen(false)}
            >
              Planos
            </NavLink>
          </li>
          <li className="border-b border-gray-200">
            <NavLink
              to="/signin"
              className="block px-4 py-2 text-sm text-gray-600 hover:text-teal-600"
              onClick={() => setIsMenuOpen(false)}
            >
              Conectar
            </NavLink>
          </li>
          <li>
            <span
              className="block px-4 py-2 text-sm text-gray-400 cursor-not-allowed"
            >
              Registrar
            </span>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default VisitorNavbar;