import React from "react";
import { Link } from "react-router-dom";

export const PaymentFailure = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-red-400 via-pink-500 to-purple-500">
      <div className="max-w-lg p-6 bg-white rounded-lg shadow-lg text-center">
        <h1 className="text-3xl font-bold text-red-600 mb-4">Pagamento Falhou</h1>
        <p className="text-gray-700 mb-6">
          Algo deu errado durante o processamento do pagamento. Por favor, tente novamente.
        </p>
        <Link
          to="/signup"
          className="py-2 px-4 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition duration-300"
        >
          Tentar Novamente
        </Link>
      </div>
    </div>
  );
};